<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      :items="income"
      :fields="fields"
      responsive
    >
      <template #cell(folio)="data">
        <div class="text-primary font-weight-bolder">
          #{{ data.value }}
        </div>
      </template>

      <template #cell(total_amount)="data">
        <div>${{ data.value | money }}</div>
      </template>
      <template #cell(income_date)="data">
        <div>{{ data.value | date }}</div>
      </template>

      <template #cell(tools)="data">
        <b-dropdown
          id="dropdown-1"
          size="sm"
          dropright
          no-caret
          variant="outline-none"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              color="#000"
            />
          </template>
          <b-dropdown-item
            :to="{ name: 'editIncome', params: { incomeId: data.item.id } }"
          >{{ $t('edit') }}
          </b-dropdown-item>
          <b-dropdown-item @click="removeIncome(data.item)">
            <span class="text-danger">
              {{ $t('delete') }}
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BTable,
  },
  props: {
    income: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'folio',
          label: 'Folio',
        },
        {
          key: 'description',
          label: this.$t('description'),
        },
        {
          key: 'total_amount',
          label: this.$t('amount-2'),
        },
        {
          key: 'income_date',
          label: this.$t('date'),
        },
        {
          key: 'tools',
          label: '',
          thStyle: { width: '10%' },
        },
      ],
    }
  },

  computed: {
    ...mapGetters('incomes', ['incomes', 'incomePagination']),
  },

  methods: {
    ...mapActions('incomes', ['deleteIncome', 'fetchIncomes']),
    removeIncome(income) {
      this.deleteIncome(income.id).then(() => {
        this.fetchIncomes({
          projectId: this.$route.params.projectId,
          pagination: this.incomePagination,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
