<template>
  <div>
    <b-row>
      <b-col
        xs="12"
        sm="6"
        md="8"
        lg="8"
        xl="9"
        class="d-flex justify-content-between pb-0 align-items-center"
      >
        <div class="d-flex w-100 justify-content-between align-items-start">
          <!-- <h1 class="font-weight-bolder">
            {{ $t('incomes') }} {{ project.name }}
          </h1> -->
          <h1>
            {{ $t('incomes') }}
          </h1>
          <b-button
            class="mr-1 mb-1 mb-md-0"
            :to="{
              name: 'newIncome',
              params: { projectId: $route.params.projectId },
            }"
            variant="primary"
          >
            {{ $t('register', { type: $t('income') }) }}
          </b-button>
        </div>
      </b-col>
      <b-col
        xs="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
      >
        <income-total-card :total-income="project.incomes_total | money" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        class="mb-2"
      >
        <div class="d-flex align-items-center">
          <span class="mr-2">{{ $t('show') }} </span>
          <b-form-select
            :value="incomePagination.per_page"
            style="width:100px"
            :options="perPageOptions"
            @change="changePerPageValue($event)"
          />
        </div>
      </b-col>
      <b-col>
        <income-table :income="incomes" />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          :value="incomePagination.current_page"
          :total-rows="incomePagination.total_objects"
          :per-page="incomePagination.per_page"
          aria-controls="my-table"
          @change="nextPage($event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IncomeTable from '@/components/income/IncomeTable.vue'
import IncomeTotalCard from '@/components/income/IncomeTotalCard.vue'

export default {
  components: {
    IncomeTable,
    IncomeTotalCard,
  },
  data() {
    return {
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
      ],
    }
  },
  computed: {
    ...mapGetters('projects', ['project']),
    ...mapGetters('incomes', ['incomes', 'incomePagination']),
  },

  mounted() {
    this.fetchIncomes({
      projectId: this.$route.params.projectId,
      pagination: { page: 1, per_page: 10 },
    })
    this.fetchProject(this.$route.params.projectId)
  },
  methods: {
    ...mapMutations('incomes', {
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapActions('incomes', ['fetchIncomes']),
    ...mapActions('projects', ['fetchProject']),
    changePerPageValue(value) {
      this.changePagination({ field: 'per_page', value })
      this.fetchIncomes({
        projectId: this.$route.params.projectId,
        pagination: this.incomePagination,
      })
    },
    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.fetchIncomes({
        projectId: this.$route.params.projectId,
        pagination: this.incomePagination,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.button-card {
  ::v-deep .card {
    margin-bottom: 0;
  }
}
</style>
